Vue.component('video-component', {
    data: function () {
        return {
        }
    },
    mounted: function () {
        this.toggleFullscreen();
    },
    methods: {
        toggleFullscreen: function(){
            if (document.querySelector('#hero-video')) {
                let player = videojs('hero-video');
                let button = document.querySelector('.request-fullscreen');

                button.addEventListener('click', function (e) {
                    player.requestFullscreen();
                    e.preventDefault();
                    player.play();
                });

                player.on('fullscreenchange', function () {
                    let fullscreen = player.isFullscreen();
                    player.muted(!fullscreen);
                    player.controls(fullscreen);

                    if (fullscreen) {
                        player.currentTime(0.1);
                        player.play();
                    }
                });
            }
        }
    }
});