Mixins.smoothScroll = {
    data() {
        return {
            MoveToInstance: new MoveTo({
                //tolerance: 100,
                duration: 800
            }),
        }
    },
    mounted() {

    },
    methods: {
        SmoothScroll() {
            const that = this;

            document.querySelectorAll('a[href^="#"]').forEach(anchor => {
                if (anchor.getAttribute('href').length > 1) {
                    anchor.addEventListener('click', function (e) {
                        e.preventDefault();

                        let target = document.querySelector(this.getAttribute('href'));
                        that.MoveToInstance.move(target);
                    });
                }
            });
        },
    }
};