Vue.component('project-slider', {
    data() {
        return {
            projectSlider: null,
            currentSlide: "01",
            totalSlides: "01",
        }
    },
    methods: {
        initializeSlider() {
            let that = this;

            this.projectSlider = new Flickity(".project-slider__slider", {
                wrapAround: true,
                prevNextButtons: false,
                pageDots: false
            });

            this.totalSlides = "0" + this.projectSlider.slides.length;

            this.projectSlider.on( 'change', function( index ) {
                that.currentSlide = "0" + (index + 1);
            });
        },
        nextSlide() {
            this.projectSlider.next(true, false);
        },
        previousSlide() {
            this.projectSlider.previous(true, false);
        }
    },
    mounted() {
        this.initializeSlider();
    }
});