Vue.component('sidebar-component', {
    data: function () {
        return {
            hasCaptcha: false,
            captchaExecuted: false,
            menuExpanded: false
        }
    },
    computed: {},
    mounted: function () {
        this.hover();
        this.activeSub();
        this.checkIfHover();
    },
    methods: {
        checkIfHover: function () {
            let active = document.querySelectorAll('.sidebar-nav__item--hover').length;
            if(active > 0) {
                let sidebarNav = document.querySelector('.sidebar-nav');
                sidebarNav.classList.add('sidebar-nav--sub-active');
            }
        },

        hover: function () {
            let component = document.querySelector('.sidebar-nav__item--active');
            if (component > 0) {
                let sidebarActive = component.offsetTop;
                document.querySelector('.sidebar-nav__indicator').style.top = sidebarActive+"px";

                let sidebarHover;

                document.querySelectorAll('.sidebar-nav__item').forEach((item) => {
                    item.addEventListener("mouseover", function( event ) {
                        sidebarHover = item.offsetTop;
                        document.querySelector('.sidebar-nav__indicator').style.top = sidebarHover+"px";
                    });

                    item.addEventListener("mouseout", function () {
                        document.querySelector('.sidebar-nav__indicator').style.top = sidebarActive+"px";
                    });
                });
            }
        },

        activeSub: function () {
            let parents = document.querySelectorAll('.sidebar-nav__item--has-sub');
            let sidebarNav = document.querySelector('.sidebar-nav');

            parents.forEach((item) => {
                item.querySelector('a').addEventListener('click', function (e) {
                    item.classList.add('sidebar-nav__item--hover');
                    sidebarNav.classList.add('sidebar-nav--sub-active');
                    e.preventDefault();
                });

                item.querySelector('.sidebar-nav__link--back').addEventListener('click', function (b) {
                    item.classList.remove('sidebar-nav__item--hover');
                    sidebarNav.classList.remove('sidebar-nav--sub-active');
                    b.preventDefault();
                });
            });
        }
    },
    created: function () {
    },
    destroyed: function () {
    }
});
